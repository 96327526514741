import React, { useState, useContext, useEffect, useRef } from "react";
import RegistroCompleto from "./RegistroCompleto";
import "../../assets/css/login.css";
import { Auth } from "../../utils/AuthContext";

import banner from "../../assets/images/segunda_2024/banner_2dareunion_completoREGSITRO.png";
import fecha from "../../assets/images/tercera_reunion/HOME/icono_calendario.png";
import lugar from "../../assets/images/tercera_reunion/HOME/icono_ubicacion.png";

import logo_banner_ergistro from "../../assets/images/tercera_reunion/HOME/logo_3reunios_ampip.png";
import titulo_img from "../../assets/images/segunda_reunion/REGISTRO/registro_ampip.png";
import titulo_img_en from "../../assets/images/segunda_reunion/REGISTRO/register_-eng.png";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Modal,
  Spin,
  InputNumber,
  DatePicker,
} from "antd";
import Firebase from "../../utils/firebaseConfig";
import {
  mail_registro_confirmacion,
  existe_empresa,
} from "../../utils/ConexionServidor";

import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";

import img_ayuda from "../../assets/images/segunda_2024/PNG/btn_probelmas.png";
import img_ayuda_en from "../../assets/images/segunda_2024/PNG/btn_probelmas.png";

import LoginExiste from "../registro/LoginExiste";

const openNotificationWithIcon = (type, titulo, texto) => {
  notification[type]({
    message: titulo,
    description: texto,
    duration: 10,
  });
};

const nom_evento = "segunda-reunion-2024";

const { Option } = Select;

let correo_registrado = "",
  password_registrado = "",
  perfil_registrado = "";

const app = new Firebase();

const success = () => {
  openNotificationWithIcon("success", "Bienvenido", "");
};

const Registro = ({ setsignup, history, tipo_registro, idioma }) => {
  const [otro_medio, setOtroMedio] = useState(false);
  const [confirmacion, setConfirmacion] = useState(false);
  const [cargando_regsitro, setCargandoRegistro] = useState(false);
  const { usuario, datos } = useContext(Auth);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [reutilizar_datos, setReutilizarDatos] = useState(false);
  const [tipo_perfl, setTipoPerfil] = useState("");
  const [tipo_hotel, setTipoHotel] = useState("");
  const [tipo_membresia, setTipoMembresia] = useState("");
  const [nombre_completo, setNombreCompleto] = useState("");
  const [empresa_repetida, setEmpresaRepetida] = useState(false);
  const [perfil_elegido, setPerfilElegido] = useState(false);
  const [personasInscritas, setPersonasInscritas] = useState(1);
  const [form] = Form.useForm();

  const ref_form = React.createRef();
  const ref_organizacion = React.createRef();
  const ref_recaptcha = React.createRef();

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const dateFormat = 'YYYY/MM/DD';


  const elegirMedio = (value) => {
    console.log(value);

    if (value === "Otro") {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }
  };

  useEffect(() => {
    if (usuario) {
      app.app
        .auth()
        .signOut()
        .then(function () { })
        .catch(function (error) {
          // An error happened.
        });
    }
  }, []);

  useEffect(() => {
    setEmpresaRepetida(false);
  }, [tipo_perfl]);

  useEffect(() => {
    console.log("se reutiliza: ", reutilizar_datos);
    if (reutilizar_datos) {
      if (datos) {
        console.log("existen los datos");

        datos.nombre_curso = nom_evento;
        //datos.apellidos = datos.ap_paterno + " " + datos.ap_materno;

        mail_registro_confirmacion(datos).then(({ data, status }) => {
          switch (status) {
            case 0:
              //delete values.aceptar_terminos;
              app.escribir(
                nom_evento + "/usuarios",
                undefined,
                datos,
                () => { }
              );

              openNotificationWithIcon("success", "", data);
              setConfirmacion(true);
              break;
            default:
              openNotificationWithIcon("warning", "", data);
              setConfirmacion(false);
              break;
          }
          //setCargandoRegistro(false);
          setMostrarLogin(false);
        });
        setReutilizarDatos(false);
      } else {
        console.log("no existen los dtos");
      }
    }
  }, [datos, reutilizar_datos]);

  const registrarLoginExiste = (medio) => {
    setReutilizarDatos(true);
  };

  const onFinish = async (values) => {
    if (empresa_repetida) {
      openNotificationWithIcon(
        "warning",
        "YA EXISTE UN REGISTRO PARA ESTA EMPRESA",
        "Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado “REGISTRO A EVENTO VIRTUAL”"
      );
      return;
    }

    setCargandoRegistro(true);

    let recaptcha = "sin-recaptcha"; //ref_recaptcha.current.getValue();

    values.correo = values.correo.toLowerCase();


    const { correo } = values;

    //setNombreCompleto(values.nombre + " " + values.ap_paterno);

    correo_registrado = correo;

    values.nombre_curso = nom_evento;
    values.personas = personasInscritas;

    //values.apellidos = values.ap_paterno;

    //values["g-recaptcha-response"] = recaptcha;
    perfil_registrado = values.perfil;

    //values.tipo_entrada = "presencial";

    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          console.log(values);
          //delete values.medios;
          //delete values.pass;
          delete values.aceptar_terminos;
          //delete values.aceptar_networking;
          //delete values.comite_infraestructura;
          //delete values.comite_asg;
          //delete values.comite_promocion;

          //delete values.otro_procedencia;

          openNotificationWithIcon("success", "", data);
          setConfirmacion(true);
          break;
        default:
          openNotificationWithIcon("warning", "", data);
          setConfirmacion(false);
          break;
      }
      setCargandoRegistro(false);
    });
  };

  const revisarEmpresa = (empresa) => {
    if (tipo_registro === "presencial") {
      existe_empresa({ empresa: empresa }).then(({ data }) => {
        if (data === 1) {
          openNotificationWithIcon(
            "warning",
            "YA EXISTE UN REGISTRO PARA ESTA EMPRESA",
            "Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado “REGISTRO A EVENTO VIRTUAL”"
          );
          setEmpresaRepetida(true);
        } else {
          setEmpresaRepetida(false);
        }
      });
    }
  };

  const revisarPErfil = (perfil) => {
    if (perfil !== "") {
      setPerfilElegido(true);
    }
  };

  return (
    <>
      <Spin tip="Cargando..." spinning={cargando_regsitro}>
        <Row style={{ backgroundColor: "#012848" }}>
          <Col span={24}>
            <Row
              justify="center"
              align="middle"
              style={{ position: "relative" }}
            >
              <Col span={24} style={{ position: "relative" }}>
                <img
                  src={idioma === "es" ? banner : banner}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col
                xs={0}
                md={0}
                style={{ position: "absolute", width: "100%" }}
              >
                <img src={logo_banner_ergistro} style={{ width: "100%" }} />
                <Row>
                  <Col xs={0} md={0}>
                    <p className="text_banner">
                      <img src={fecha} style={{ width: "40px" }} /> 15 al 18 de noviembre <br />de 2023
                    </p>
                  </Col>
                  <Col xs={0} md={0}>
                    <p className="text_banner">
                      <a href="https://goo.gl/maps/CuSqvV8vFAAqTZPL7" style={{ color: 'white' }} target="_blank"><img src={lugar} style={{ width: "40px" }} />JW MARRIOTT - Los Cabos, México</a>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {confirmacion ? (
            <Col span={24}>
              <RegistroCompleto
                correo={correo_registrado}
                password={password_registrado}
                tipo_registro={tipo_registro}
                nombre={nombre_completo}
                idioma={idioma}
              />
            </Col>
          ) : (
            <>
              <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }}>
                <Form
                  className="login-form"
                  ref={ref_form}
                  form={form}
                  layout="vertical"
                  name="basic"
                  initialValues={{
                    remember: true,
                    procedencia: "",
                    checkin1: "2024-06-19",
                    checkout1: "2024-06-21",
                    checkin2: "2024-06-19",
                    checkout2: "2024-06-21",
                    checkin3: "2024-06-19",
                    checkout3: "2024-06-21",
                    checkin4: "2024-06-19",
                    checkout4: "2024-06-21",
                    checkin5: "2024-06-19",
                    checkout5: "2024-06-21",
                    checkin6: "2024-06-19",
                    checkout6: "2024-06-21"
                  }}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col span={20} offset={2}>
                      <Row
                        justify="center"
                        align="middle"
                      >
                        <Col span={18} >
                          <p className="textos_titulos_secciones_ color_blanco">REGISTRO</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 0 }}
                    >
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="nombre1"
                        label={
                          <span className="labels ">
                            Nombre(s):
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa tu nombre
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 2 }}
                    >
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="ap_paterno1"
                        label={
                          <span className="labels ">
                            {idioma === "es"
                              ? "Apellido Paterno:"
                              : "Last Name"}
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa tu apellido paterno
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 0 }}
                    >
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="alias1"
                        label={
                          <span className="labels ">
                            Nombre con el cual quiere aparecer en el gafete:
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa el nombre que quieres que aparezca en el Gafete
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                        />
                      </Form.Item>
                    </Col>
                    {/*<Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 2 }}
                    >
                      <Form.Item style={{ display: 'none' }}
                        className="nom-label"
                        name="genero1"
                        label={
                          <span className="labels ">
                            Genero:
                          </span>
                        }
                        rules={[
                          {
                            required: false,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa tu genero
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                        />
                      </Form.Item>
                      </Col>*/}
                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 2 }}>
                      <Form.Item
                        className="nom-label"
                        name="correo"
                        label={
                          <span className="labels ">
                            Correo electrónico
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa un correo válido
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 0 }}>
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="perfil1"
                        label={
                          <span className="labels ">
                            Perfil{" "}
                            <small>
                              (De clic en la opción deseada)
                            </small>
                            :
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                {idioma === "es"
                                  ? "Selecciona un perfil"
                                  : "Select a profile"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%", textAlign: "left", display: 'non' }}
                          onChange={(e) => {
                            setTipoPerfil(e);
                            console.log(
                              ref_form.current.resetFields(["procedencia"])
                            );
                          }}
                        >
                          <Option value="Asociado">Asociado</Option>
                          <Option value="Patrocinador Platino">Patrocinador Platino</Option>
                          <Option value="Patrocinador Oro">Patrocinador Oro</Option>
                          <Option value="Patrocinador Bronce">Patrocinador Bronce</Option>
                          <Option value="Invitado">
                            Invitado
                          </Option>
                          <Option value="Conferencista">
                            Conferencista
                          </Option>
                          <Option value="Equipo AMPIP">Equipo AMPIP</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {tipo_perfl === "Asociado" && (
                      <Col xs={{ span: 24, offset: 0 }}
                        md={{ span: 11, offset: 2 }}>
                        <Form.Item
                          className="nom-label"
                          name="procedencia1"
                          label={
                            <span className="labels ">
                              Socio{" "}
                              <small>
                                (Da clic en la flecha para seleccionar la opción
                                deseada)
                              </small>
                              :
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: (
                                <span style={{ color: "red" }} className="">
                                  Selecciona un socio
                                </span>
                              ),
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "100%", textAlign: "left" }}
                            onChange={(e) => {
                              setTipoMembresia(e);
                              console.log(
                                ref_form.current.resetFields(["procedencia"])
                              );
                            }}
                          >
                            <Option value="Advance Real Estate">Advance Real Estate</Option>
                            <Option value="AECOM">AECOM</Option>
                            <Option value="Aire Proyectos del Golfo">Aire Proyectos del Golfo</Option>
                            <Option value="Alas Group">Alas Group</Option>
                            <Option value="Alliuz">Alliuz</Option>
                            <Option value="American Industries">American Industries</Option>
                            <Option value="Ammper">Ammper</Option>
                            <Option value="Artha Capital - Frontier Industrial ">Artha Capital - Frontier Industrial </Option>
                            <Option value="ATISA Industrial">ATISA Industrial</Option>
                            <Option value="Axial Grupo">Axial Grupo</Option>
                            <Option value="Axtel">Axtel</Option>
                            <Option value="Baker & McKenzie ">Baker & McKenzie </Option>
                            <Option value="Bancomext">Bancomext</Option>
                            <Option value="Banorte">Banorte</Option>
                            <Option value="BBVA">BBVA</Option>
                            <Option value="Bekaert">Bekaert</Option>
                            <Option value="BEMI Industrial">BEMI Industrial</Option>
                            <Option value="Bterra Desarrollos">Bterra Desarrollos</Option>
                            <Option value="BTS Development">BTS Development</Option>
                            <Option value="Cactus Valley Industrial Parks">Cactus Valley Industrial Parks</Option>
                            <Option value="Canadian Pacific Kansas City">Canadian Pacific Kansas City</Option>
                            <Option value="Carlisle">Carlisle</Option>
                            <Option value="Carza Industrial Developments">Carza Industrial Developments</Option>
                            <Option value="CBRE">CBRE</Option>
                            <Option value="Cementos Moctezuma">Cementos Moctezuma</Option>
                            <Option value="CEMEX">CEMEX</Option>
                            <Option value="Central Business Park Merida">Central Business Park Merida</Option>
                            <Option value="CIESA">CIESA</Option>
                            <Option value="Colliers México">Colliers México</Option>
                            <Option value="Construcciones MS">Construcciones MS</Option>
                            <Option value="Constructora Insur">Constructora Insur</Option>
                            <Option value="Construlita">Construlita</Option>
                            <Option value="Copachisa">Copachisa</Option>
                            <Option value="Corporate Properties">Corporate Properties</Option>
                            <Option value="Creel, García-Cuéllar, Aiza y Enríquez">Creel, García-Cuéllar, Aiza y Enríquez</Option>
                            <Option value="Davisa Desarrollos Inmobiliarios">Davisa Desarrollos Inmobiliarios</Option>
                            <Option value="DeAcero">DeAcero</Option>
                            <Option value="Deltack">Deltack</Option>
                            <Option value="Egis">Egis</Option>
                            <Option value="El Florido Parque Industrial">El Florido Parque Industrial</Option>
                            <Option value="Empire Roofing">Empire Roofing</Option>
                            <Option value="Enel">Enel</Option>
                            <Option value="Energía Real">Energía Real</Option>
                            <Option value="Enlight">Enlight</Option>
                            <Option value="EQT Exeter">EQT Exeter</Option>
                            <Option value="Estructuras Solana">Estructuras Solana</Option>
                            <Option value="FIBRA Macquarie">FIBRA Macquarie</Option>
                            <Option value="FIBRA MTY">FIBRA MTY</Option>
                            <Option value="FIBRA UNO (FUNO)">FIBRA UNO (FUNO)</Option>
                            <Option value="FINSA">FINSA</Option>
                            <Option value="Fortem Capital">Fortem Capital</Option>
                            <Option value="FRISA">FRISA</Option>
                            <Option value="Global Hitss">Global Hitss</Option>
                            <Option value="GP Desarrollos">GP Desarrollos</Option>
                            <Option value="Grupo Avante">Grupo Avante</Option>
                            <Option value="Grupo Ayusa">Grupo Ayusa</Option>
                            <Option value="Grupo Bahn">Grupo Bahn</Option>
                            <Option value="Grupo Cadena">Grupo Cadena</Option>
                            <Option value="Grupo Chufani">Grupo Chufani</Option>
                            <Option value="Grupo GMI">Grupo GMI</Option>
                            <Option value="Grupo Nelson">Grupo Nelson</Option>
                            <Option value="Hermosillo">Hermosillo</Option>
                            <Option value="Hikvision">Hikvision</Option>
                            <Option value="Hines">Hines</Option>
                            <Option value="Holcim">Holcim</Option>
                            <Option value="Huawei">Huawei</Option>
                            <Option value="IAMSA Development Group">IAMSA Development Group</Option>
                            <Option value="Iberdrola México">Iberdrola México</Option>
                            <Option value="IFC">IFC</Option>
                            <Option value="Imobilem">Imobilem</Option>
                            <Option value="Industralia">Industralia</Option>
                            <Option value="Industrialix">Industrialix</Option>
                            <Option value="Interlogix">Interlogix</Option>
                            <Option value="Intermex Parques Industriales">Intermex Parques Industriales</Option>
                            <Option value="Interpuerto Monterrey">Interpuerto Monterrey</Option>
                            <Option value="Isocindu">Isocindu</Option>
                            <Option value="JLL">JLL</Option>
                            <Option value="Jumbo City">Jumbo City</Option>
                            <Option value="Kampus Desarrollos">Kampus Desarrollos</Option>
                            <Option value="Kiewit">Kiewit</Option>
                            <Option value="Kingspan">Kingspan</Option>
                            <Option value="Lintel">Lintel</Option>
                            <Option value="Marabis">Marabis</Option>
                            <Option value="Mazatlan Logistics Center (MLC)">Mazatlan Logistics Center (MLC)</Option>
                            <Option value="MEOR">MEOR</Option>
                            <Option value="Monex">Monex</Option>
                            <Option value="Mopec Security">Mopec Security</Option>
                            <Option value="Naturgy">Naturgy</Option>
                            <Option value="Newmark">Newmark</Option>
                            <Option value="Nexxus">Nexxus</Option>
                            <Option value="NICOYA Inversiones">NICOYA Inversiones</Option>
                            <Option value="Novotech SiNow">Novotech SiNow</Option>
                            <Option value="O'Donnell">O'Donnell</Option>
                            <Option value="Oradel">Oradel</Option>
                            <Option value="Owens Corning Mexico">Owens Corning Mexico</Option>
                            <Option value="Parque ALIS">Parque ALIS</Option>
                            <Option value="Parque Industrial AeroTech">Parque Industrial AeroTech</Option>
                            <Option value="Parque Industrial Atitalaquia">Parque Industrial Atitalaquia</Option>
                            <Option value="Parque Industrial Bermúdez">Parque Industrial Bermúdez</Option>
                            <Option value="Parque Industrial Calafia">Parque Industrial Calafia</Option>
                            <Option value="Parque Industrial Maran">Parque Industrial Maran</Option>
                            <Option value="Parque Industrial Millennium">Parque Industrial Millennium</Option>
                            <Option value="Parque Industrial Opción">Parque Industrial Opción</Option>
                            <Option value="Parque Industrial PyME">Parque Industrial PyME</Option>
                            <Option value="Parque Industrial San Jorge">Parque Industrial San Jorge</Option>
                            <Option value="Parque Logístico San Julián">Parque Logístico San Julián</Option>
                            <Option value="Parques Industriales Amistad">Parques Industriales Amistad</Option>
                            <Option value="Pecari">Pecari</Option>
                            <Option value="PGIM Real Estate">PGIM Real Estate</Option>
                            <Option value="PIMSA Desarrolladores Industriales">PIMSA Desarrolladores Industriales</Option>
                            <Option value="Plataforma Park">Plataforma Park</Option>
                            <Option value="Polígono Empresarial San Miguel de Allende">Polígono Empresarial San Miguel de Allende</Option>
                            <Option value="Powen">Powen</Option>
                            <Option value="Prologis">Prologis</Option>
                            <Option value="Prosperity Industrial">Prosperity Industrial</Option>
                            <Option value="ProximityParks">ProximityParks</Option>
                            <Option value="Puerta Querétaro">Puerta Querétaro</Option>
                            <Option value="RER Energy Group">RER Energy Group</Option>
                            <Option value="RMSG">RMSG</Option>
                            <Option value="ROCA Desarrollos">ROCA Desarrollos</Option>
                            <Option value="Santander">Santander</Option>
                            <Option value="Savills">Savills</Option>
                            <Option value="SEZAC, Gobierno del Estado de Zacatecas">SEZAC, Gobierno del Estado de Zacatecas</Option>
                            <Option value="SkyPlus Industrial and Logistics Park">SkyPlus Industrial and Logistics Park</Option>
                            <Option value="Soprema">Soprema</Option>
                            <Option value="Stabilit">Stabilit</Option>
                            <Option value="STIVA Industrial Developments">STIVA Industrial Developments</Option>
                            <Option value="Telmex">Telmex</Option>
                            <Option value="TENSA Construcciones">TENSA Construcciones</Option>
                            <Option value="Terra Regia">Terra Regia</Option>
                            <Option value="Terrafina">Terrafina</Option>
                            <Option value="Tetakawi">Tetakawi</Option>
                            <Option value="Thor Urbana">Thor Urbana</Option>
                            <Option value="Urbium GDI">Urbium GDI</Option>
                            <Option value="USG">USG</Option>
                            <Option value="VESTA">VESTA</Option>
                            <Option value="VYNMSA">VYNMSA</Option>
                            <Option value="Walton Street Capital Mexico">Walton Street Capital Mexico</Option>
                            <Option value="World Port Industrial Park">World Port Industrial Park</Option>
                            <Option value="WTC Industrial">WTC Industrial</Option>
                            <Option value="Xsite Properties">Xsite Properties</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {(tipo_perfl === "Patrocinador Platino" || tipo_perfl === "Patrocinador Oro" || tipo_perfl === "Patrocinador  Bronce" || tipo_perfl === "Invitados" || tipo_perfl === "Conferencista") && (
                      <Col xs={{ span: 24, offset: 0 }}
                        md={{ span: 11, offset: 2 }}>
                        <Form.Item
                          className="nom-label"
                          name="procedencia1"
                          label={
                            <span className="labels">
                              Corporativo:
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: (
                                <span style={{ color: "red" }}>
                                  {idioma === "es"
                                    ? "Ingrese un Corporativo:"
                                    : "Enter a membership:"}
                                </span>
                              ),
                            },
                          ]}
                          initialValue=""
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    )}
                    {(tipo_perfl === 'Equipo AMPIP' || tipo_perfl === '') &&
                      <Col xs={{ span: 0, offset: 0 }}
                        md={{ span: 12, offset: 0 }}>
                      </Col>}
                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 0 }}>
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="cargo1"
                        label={
                          <span className="labels">
                            {idioma === "es" ? "Cargo:" : "Position:"}
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }}>
                                {idioma === "es"
                                  ? "Ingresa tu cargo"
                                  : "Enter your position"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input className="inputs" autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 2 }}>
                      <Form.Item style={{ display: 'none' }}
                        className="nom-label"
                        name="hotel1"
                        label={
                          <span className="labels">
                            Se hospeda en el Hotel Sede
                          </span>
                        }
                        rules={[
                          {
                            required: false,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Selecciona una opción
                              </span>
                            ),
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%", textAlign: "left", display: 'non' }}
                          onChange={(e) => {
                            console.log(
                              ref_form.current.resetFields(["hotel"])
                            );
                          }}
                        >
                          <Option value="Si">Si</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 0 }}>
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="checkin1"
                        label={
                          <span className="labels">
                            Fecha de llegada
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Selecciona una fecha
                              </span>
                            ),
                          },
                        ]}
                      >
                        {/*<DatePicker onChange={onChange} className="inputs" autoComplete="off" format={dateFormat}  />*/}
                        <input type="date"
                          className="inputs"
                          min="2024-06-18" max="2024-06-22"
                          placeholder="AAAA-MM-DD"></input>
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 2 }}>
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="checkout1"
                        label={
                          <span className="labels">
                            Fecha de salida
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Selecciona una fecha
                              </span>
                            ),
                          },
                        ]}
                      >
                        {/*<DatePicker onChange={onChange} className="inputs" autoComplete="off" format={dateFormat}  />*/}
                        <input type="date"
                          className="inputs"
                          min="2024-06-18" max="2023-11-30"
                          placeholder="AAAA-MM-DD"></input>
                      </Form.Item>
                    </Col>
                    {personasInscritas >= 2 &&
                      <>
                        <Col span={24}><br />
                          <p className="labels">
                            ACOMPAÑANTE 1
                          </p>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}
                        >

                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="nombre2"
                            label={
                              <span className="labels ">
                                Nombre(s):
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu nombre
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}
                        >
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="ap_paterno2"
                            label={
                              <span className="labels ">
                                {idioma === "es"
                                  ? "Apellido Paterno:"
                                  : "Last Name"}
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu apellido paterno
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 0, offset: 0 }}
                          md={{ span: 0, offset: 0 }}
                        >
                          <Form.Item style={{ display: 'none' }}
                            className="nom-label"
                            name="genero2"
                            label={
                              <span className="labels ">
                                Genero:
                              </span>
                            }
                            rules={[
                              {
                                required: false,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu genero
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="hotel2"
                            label={
                              <span className="labels">
                                Se hospeda en el Hotel Sede
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una opción
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%", textAlign: "left", display: 'non' }}
                              onChange={(e) => {
                                console.log(
                                  ref_form.current.resetFields(["hotel"])
                                );
                              }}
                            >
                              <Option value="Si">Si</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkin2"
                            label={
                              <span className="labels">
                                Fecha de llegada
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkout2"
                            label={
                              <span className="labels">
                                Fecha de salida
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                      </>
                    }
                    {personasInscritas >= 3 &&
                      <>
                        <Col span={24}><br />
                          <p className="labels">
                            ACOMPAÑANTE 2
                          </p>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}
                        >
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="nombre3"
                            label={
                              <span className="labels ">
                                Nombre(s):
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu nombre
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}
                        >
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="ap_paterno3"
                            label={
                              <span className="labels ">
                                {idioma === "es"
                                  ? "Apellido Paterno:"
                                  : "Last Name"}
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu apellido paterno
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 0, offset: 0 }}
                          md={{ span: 0, offset: 0 }}
                        >
                          <Form.Item style={{ display: 'none' }}
                            className="nom-label"
                            name="genero3"
                            label={
                              <span className="labels ">
                                Genero:
                              </span>
                            }
                            rules={[
                              {
                                required: false,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu genero
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="hotel3"
                            label={
                              <span className="labels">
                                Se hospeda en el Hotel Sede
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una opción
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%", textAlign: "left", display: 'non' }}
                              onChange={(e) => {
                                console.log(
                                  ref_form.current.resetFields(["hotel"])
                                );
                              }}
                            >
                              <Option value="Si">Si</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={12}></Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkin3"
                            label={
                              <span className="labels">
                                Fecha de llegada
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkout3"
                            label={
                              <span className="labels">
                                Fecha de salida
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                      </>
                    }
                    {personasInscritas >= 4 &&
                      <>
                        <Col span={24}><br />
                          <p className="labels">
                            ACOMPAÑANTE 3
                          </p>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}
                        >
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="nombre4"
                            label={
                              <span className="labels ">
                                Nombre(s):
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu nombre
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}
                        >
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="ap_paterno4"
                            label={
                              <span className="labels ">
                                {idioma === "es"
                                  ? "Apellido Paterno:"
                                  : "Last Name"}
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu apellido paterno
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 0, offset: 0 }}
                          md={{ span: 0, offset: 0 }}
                        >
                          <Form.Item style={{ display: 'none' }}
                            className="nom-label"
                            name="genero4"
                            label={
                              <span className="labels ">
                                Genero:
                              </span>
                            }
                            rules={[
                              {
                                required: false,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu genero
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="hotel4"
                            label={
                              <span className="labels">
                                Se hospeda en el Hotel Sede
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una opción
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%", textAlign: "left", display: 'non' }}
                              onChange={(e) => {
                                console.log(
                                  ref_form.current.resetFields(["hotel"])
                                );
                              }}
                            >
                              <Option value="Si">Si</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={12}></Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkin4"
                            label={
                              <span className="labels">
                                Fecha de llegada
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkout4"
                            label={
                              <span className="labels">
                                Fecha de salida
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                      </>
                    }
                    {personasInscritas >= 5 &&
                      <>
                        <Col span={24}><br />
                          <p className="labels">
                            ACOMPAÑANTE 4
                          </p>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}
                        >
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="nombre5"
                            label={
                              <span className="labels ">
                                Nombre(s):
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu nombre
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}
                        >
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="ap_paterno5"
                            label={
                              <span className="labels ">
                                {idioma === "es"
                                  ? "Apellido Paterno:"
                                  : "Last Name"}
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu apellido paterno
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 0, offset: 0 }}
                          md={{ span: 0, offset: 0 }}
                        >
                          <Form.Item style={{ display: 'none' }}
                            className="nom-label"
                            name="genero5"
                            label={
                              <span className="labels ">
                                Genero:
                              </span>
                            }
                            rules={[
                              {
                                required: false,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu genero
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="hotel5"
                            label={
                              <span className="labels">
                                Se hospeda en el Hotel Sede
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una opción
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%", textAlign: "left", display: 'non' }}
                              onChange={(e) => {
                                console.log(
                                  ref_form.current.resetFields(["hotel"])
                                );
                              }}

                            >
                              <Option value="Si">Si</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={12}></Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkin5"
                            label={
                              <span className="labels">
                                Fecha de llegada
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkout5"
                            label={
                              <span className="labels">
                                Fecha de salida
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                      </>
                    }
                    {personasInscritas === 6 &&
                      <>
                        <Col span={24}><br />
                          <p className="labels">
                            ACOMPAÑANTE 5
                          </p>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}
                        >
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="nombre6"
                            label={
                              <span className="labels ">
                                Nombre(s):
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu nombre
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}
                        >
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="ap_paterno6"
                            label={
                              <span className="labels ">
                                {idioma === "es"
                                  ? "Apellido Paterno:"
                                  : "Last Name"}
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu apellido paterno
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}
                        >
                          <Form.Item style={{ display: 'none' }}
                            className="nom-label"
                            name="genero6"
                            label={
                              <span className="labels ">
                                Genero:
                              </span>
                            }
                            rules={[
                              {
                                required: false,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Ingresa tu genero
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <input
                              className="inputs"
                              autoComplete="nope"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 0, offset: 0 }}
                          md={{ span: 0, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="hotel6"
                            label={
                              <span className="labels">
                                Se hospeda en el Hotel Sede
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una opción
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%", textAlign: "left", display: 'non' }}
                              onChange={(e) => {
                                console.log(
                                  ref_form.current.resetFields(["hotel"])
                                );
                              }}

                            >
                              <Option value="Si">Si</Option>
                              <Option value="No">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={12}></Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 0 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkin6"
                            label={
                              <span className="labels">
                                Fecha de llegada
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }}
                          md={{ span: 11, offset: 2 }}>
                          <Form.Item style={{ display: 'non' }}
                            className="nom-label"
                            name="checkout6"
                            label={
                              <span className="labels">
                                Fecha de salida
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: (
                                  <span style={{ color: "red" }} className="">
                                    Selecciona una fecha
                                  </span>
                                ),
                              },
                            ]}
                          ><input type="date"
                            className="inputs"
                            min="2024-06-18" max="2024-06-22"
                            placeholder="AAAA-MM-DD"></input>
                          </Form.Item>
                        </Col>
                      </>
                    }
                    <Col span={24} style={{ textAlign: "left" }}>
                      <br />
                      <Form.Item
                        className="nom-label"
                        name="aceptar_terminos"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                  <span style={{ color: "red" }}>
                                    {idioma === "es"
                                      ? "He leído y acepto los términos y condiciones."
                                      : "I have read and accept the terms and conditions."}
                                  </span>
                                ),
                          },
                        ]}
                      >
                        <Checkbox className="nom-label">
                          <span
                            className="color_blanco"
                            style={{ fontSize: "16px" }}
                          >
                            {idioma === "es" ? (
                              <>
                                Acepto el{" "}
                                <span
                                  onClick={() => {
                                    window.open(
                                      "https://www.ampip.org.mx/aviso",
                                      "Aviso de privacidad",
                                      "top=100,left=500,width=600,height=600"
                                    );
                                  }}
                                >
                                  <strong>Aviso de Privacidad</strong>
                                </span>
                              </>
                            ) : (
                              <>
                                I accept{" "}
                                <span
                                  onClick={() => {
                                    window.open(
                                      "https://www.ampip.org.mx/aviso",
                                      "Aviso de privacidad",
                                      "top=100,left=500,width=600,height=600"
                                    );
                                  }}
                                >
                                  <strong>the Privacy Notice</strong>
                                </span>
                              </>
                            )}
                          </span>

                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item>
                        <br />
                        <p style={{ textArlign: "center" }}>
                          <span
                            className="btn_aqua"
                            onClick={() => {
                              ref_form.current.submit();
                            }}
                          >
                            {idioma === "es" ? "REGISTRARME" : "REGISTER"}
                          </span>
                        </p>
                        <br />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div id="recaptcha-container"></div>
                </Form>
              </Col>
              <Col xs={{ offset: 2, span: 20 }} md={{ offset: 6, span: 12 }}>
                <a
                  href="https://api.whatsapp.com/send?phone=525549826570&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                  target="_blank"
                >
                  <img
                    style={{ width: "100%" }}
                    src={idioma === "es" ? img_ayuda : img_ayuda_en}
                    alt="Entrar"
                  />
                </a>
              </Col>
              <Col span={24}>
                <br />
                <br />
              </Col>
              <Col span={24} className="back_morado paddin_contenedor">
                <Row align="middle">
                  <Col
                    xs={{ span: 20, offset: 2 }}
                    md={{ span: 20, offset: 2 }}
                  >
                    <span className='leyenda color_blanco'>Dudas e información con Cecilia Toribio / <strong><a className="color_blanco" href="mailto:ctoribio@ampip.org.mx">ctoribio@ampip.org.mx</a></strong></span>
                  </Col>

                </Row>
              </Col>
            </>
          )}
        </Row>
      </Spin>

      <Modal
        title="INICIA SESIÓN"
        open={mostrar_login}
        onOk={() => {
          setMostrarLogin(false);
        }}
        onCancel={() => {
          setMostrarLogin(false);
        }}
        footer={null}
      >
        <LoginExiste
          onResponse={() => {
            registrarLoginExiste();
          }}
          onClick={success}
        />
      </Modal>
    </>
  );
};

export default withRouter(Registro);
