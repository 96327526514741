import React, { useContext, useState, useEffect } from 'react';
import {Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, Button, Menu, Pagination, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Element } from 'react-scroll';
import Buscar from './Buscar';
import '../../assets/css/networking.css';
import Cards from './Cards';

const Networking = (props) =>{

  const [categoria, verCategoria] = useState('todos');

  const handleMenuClick = (key) =>{
    console.log(key);
    verCategoria(key.key);
  }
  
  const menu_categorias = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Miembro AMPIP" texto="Miembro AMPIP" >Miembro AMPIP</Menu.Item>
      <Menu.Item key="Patrocinador Platino" texto="Patrocinador Platino" >Patrocinador Platino</Menu.Item>
      <Menu.Item key="Patrocinador Oro" texto="Patrocinador Oro" >Patrocinador Oro</Menu.Item>
      <Menu.Item key="todos" texto="Todos" >Todos</Menu.Item>
    </Menu>
  );

  return(
    <>
    <Row >
    <Col span={24} className=""><br /><br /><br /><br />
        <p className="textos_titulos_secciones">DIRECTORIO</p>
      </Col>
      <Col span={24} className="cont_texto_cabeza_net back_azul_claro">
        <Row>
          <Col span={20} offset={2}>
            <p className="texto_cabeza_net">Aquí encontrarás a todos los asistentes. <br />
            ¡Aprovecha la oportunidad y conecta con ellos!</p>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="row_cont_net ">
      <Col xs={0} md={{span:5, offset:2}}>
        <Row className="contenedor_perfiles">
          <Col className="cont_titulo_perfil" span={24}>
            <p className="titulo_perfil">PERFILES</p>
          </Col>
          <Col className="" md={24}>            
            <p className={categoria === 'Miembro AMPIP' ? 'text_perfil_activo' : 'text_perfil'} onClick={() => verCategoria('Miembro AMPIP')}>Miembro AMPIP</p>
            <p className={categoria === 'Patrocinador Platino' ? 'text_perfil_activo' : 'text_perfil'} onClick={() => verCategoria('Patrocinador Platino')}>Patrocinador Platino</p>
            <p className={categoria === 'Patrocinador Oro' ? 'text_perfil_activo' : 'text_perfil'} onClick={() => verCategoria('Patrocinador Oro')}>Patrocinador Oro</p>
            
            <p className={categoria === 'todos' ? 'text_perfil_activo' : 'text_perfil'} onClick={() => verCategoria('todos')}>Todos</p>        
          </Col>
        </Row>
      </Col>
      <Col xs={{span:18, offset:3}} sm={{span:16, offset:4}} md={{span:0, offset:0}} className="contenedor_blog_movil">
        <Row justify="space-around" align="middle">
          <Col span={8} style={{ textAlign:'left' }}>
            <Dropdown overlay={menu_categorias}>
              <Button>
                PERFILES <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col span={16}>
            <p className="opcion_text_categoria">{categoria}</p>
          </Col>
        </Row>
      </Col>
      <Col xs={{span:22, offset:1}} md={{span:15, offset:0}}>
      <Row justify="center" align="middle">
      
      <Col span={24}>
      
      <Cards categoria={categoria}/>
    
      
      </Col>
      
      
    </Row>
    
      </Col>
    </Row>
    </>
  );
}

export default Networking;