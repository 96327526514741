import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import "../../assets/css/ponentes.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_conferencistas.png';
import sergio_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Sergio_Argaelles_espanol.jpg";
import peter_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Peter_Linneman_Ingles.pdf";
import matt_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Matt_Brady_ingles.pdf";
import hamid_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Hamid_Moghadam_espanol.pdf";
import { CaretLeftFilled, CaretRightFilled, CloseOutlined } from "@ant-design/icons";

import ponente1 from "../../assets/images/anual_2024/ponentes/GuillermoEspinosa.jpg";
import ponente2 from "../../assets/images/anual_2024/ponentes/JorgeAvalos.jpg";
import ponente3 from "../../assets/images/anual_2024/ponentes/MarceloEbrard.jpg";
import ponente4 from "../../assets/images/anual_2024/ponentes/RodrigoPacheco.jpg";
import ponente5 from "../../assets/images/anual_2024/ponentes/LilaAbed.jpg";
import ponente6 from "../../assets/images/anual_2024/ponentes/GerardoRamirez.jpg";
import ponente7 from "../../assets/images/anual_2024/ponentes/EnriqueLavin.jpg";
import ponente8 from "../../assets/images/anual_2024/ponentes/RubenDuque.jpg";
import ponente9 from "../../assets/images/anual_2024/ponentes/MarcSelvitelli.jpg";


const expositor = [
  //{nombre:'', img: avatar,img_hover:avatar_hover, hash:'/conferencistas#' },
  {
    nombre: "Guillermo Espinosa",
    empresa: "",
    cargo: "",
    img: ponente1,
    url: sergio_con,
    semblanza: [<> Guillermo es el jefe de desarrollo en México de DHL Supply Chain-Real Estate Solutions, una subsidiaria de DHL Group. La empresa ofrece una gama de servicios para los fabricantes de la zona, como la logística, la planificación estratégica, la consultoría de la cadena de suministro y la distribución. Cuenta con más de dos décadas de experiencia en desarrollo industrial en México.
    </>],
  },{
    nombre: "Jorge Ávalos",
    empresa: "Presidente de AMPIP",
    cargo: "",
    img: ponente2,
    url: sergio_con,
    semblanza: [<>Con más de 30 años de experiencia en el ámbito inmobiliario y financiero, Jorge Avalos es director general y cofundador de Fibra Monterrey empresa que realizó su oferta pública inicial en la Bolsa Mexicana de Valores, en diciembre del 2014 y cuya especialización es la propiedad y administración naves industriales primordialmente. 
      <br /><br />
      Es accionista y presidente de Escala. Empresa fundada en 1990 y pionera en la administración profesional de proyectos para el sector de bienes raíces en México. Actualmente, es el Presidente del Consejo Directivo de la Asociación Mexicana de Parques Industriales Privados (AMPIP).
      <br /><br />
      Tiene una trayectoria de más de 20 años como miembro de distintos consejos de administración de empresas públicas y privadas.
      <br /><br />
      Jorge es licenciado en contaduría del Instituto Tecnológico Autónomo de México (ITAM), con maestría en negocios en la Universidad de Dallas, diplomado de negocios por la Universidad de Harvard y programa de alta dirección D2 por el Instituto Panamericano de Alta Dirección (IPADE).      
      </>],
  },{
    nombre: "Marcelo Ebrard",
    empresa: "",
    cargo: "",
    img: ponente3,
    url: sergio_con,
    semblanza: [<>
    </>],
  },{
    nombre: "Rodrigo Pacheco",
    empresa: "",
    cargo: "Periodista de negocios.",
    img: ponente4,
    url: sergio_con,
    semblanza: [<>Rodrigo Pacheco es un periodista de negocios, ha impartido conferencias a cámaras empresariales, líderes de negocios, empresas y universidades, con el enfoque de transmitir un análisis de México y su desenvolvimiento económico influido por factores políticos y sociales. Además aborda las grandes tendencias globales por las que navega el país en el siglo XXI. Rodrigo actualmente es el titular de Imagen Empresarial, programa de radio en donde se abordan temas del mundo corporativo, economía y finanzas, con un enfoque fresco y dinámico con un fuerte énfasis en la información internacional, de tecnología e innovación y su vinculación con la coyuntura mexicana.<br />
      Además, Rodrigo colabora en distintos espacios informativos de Grupo Imagen Multimedia y publica una columna semanal en el periódico Excélsior.<br /><br />
      Es miembro del consejo editorial de la Revista ISTMO del IPADE. Rodrigo es licenciado en Relaciones Internacionales egresado del Tecnológico de Monterrey.
    </>],
  },{
    nombre: "Gerardo Ramírez",
    empresa: "",
    cargo: "",
    img: ponente6,
    url: sergio_con,
    semblanza: [<>Gerardo has been leading the JLL Corporate Industrial Services for 15 years. With a deep knowledge of the different industrial markets in Mexico, he has helped clients from all over the world to perform projects in different cities. As an active member of different international groups, Gerardo coordinates and operates together with the 7 JLL offices in Mexico all the information and negotiations that are needed to buy or lease an industrial facility, participating in several businesses of industrial and offices (in Monterrey) related to site selection, tenant representation, opinions of value, acquisitions, dispositions, leasing, development and client advisories.<br /><br />
      Before joining JLL Mexico’s in early 2009, Mr. Ramírez worked for the Mexican Government in Ports planning; later managing two Construction Companies; and lastly as Vice President of Operations and Development in G. Acción / AMB Property Mexico (now Prologis) leading in Mexico the acquisitions, development and leasing teams.      
    </>],
  },{
    nombre: "Lila Abed",
    empresa: "",
    cargo: "",
    img: ponente5,
    url: sergio_con,
    semblanza: [<>Lila Abed es la Directora Interina del Instituto de México. Anteriormente, se desempeñó como Subdirectora. Antes de convertirse en Subdirectora del Instituto de México, Lila fue corresponsal de la Casa Blanca para el canal de noticias internacional NTN24. Como analista internacional apasionada, utiliza sus habilidades en una variedad de redes de medios, incluyendo sus roles como colaboradora permanente en Radio Fórmula y columnista semanal para El Heraldo de México y Opinión51.<br /><br />
      Lila trabajó como Asesora de Políticas Públicas para América Latina en el bufete internacional Covington & Burling en Washington, DC, después de haber sido Asesora de Asuntos Internacionales para la Comisión de Relaciones Exteriores de la Cámara de Diputados de México. También trabajó en varias instituciones públicas, incluidas las Embajadas de México en Canadá y en el Reino Unido. Lila adquirió una gran experiencia durante su tiempo en la Fiscalía General de México, donde fue Directora General de Cooperación Internacional, seguida de un período como Asesora de Asuntos Internacionales y posteriormente como Jefa de Gabinete del Fiscal General. Anteriormente ocupó el cargo de Secretaria de Asuntos Internacionales en el Comité Ejecutivo del Partido Revolucionario Institucional. Lila también se postuló para un escaño en la Cámara de Diputados de la Ciudad de México.<br /><br />
      Cuenta con una maestría en Estudios Latinoamericanos y Gobierno de la Universidad de Georgetown. Se graduó magna cum laude con una licenciatura en Estudios Internacionales y Estudios Hispánicos y una especialización en Chino en Boston College.
    </>],
  },{
    nombre: "Enrique Lavín ",
    empresa: "",
    cargo: "",
    img: ponente7,
    url: sergio_con,
    semblanza: [<>
    Enrique Lavín es Director General de México de PGIM Real Estate, donde también funge como jefe de Administración de Portafolios, Latinoamérica.<br /><br />
    Es responsable de proporcionar dirección estratégica y supervisar el desempeño financiero de los activos bajo administración de PGIM, por casi US$3,000 millones. Asimismo, Enrique es encargado de dirigir el desarrollo de nuevos negocios y por lo tanto, responsable de estructurar las iniciativas de levantamiento de capital público y privado para la región , así como de administrar las relaciones de PGIM Real Estate con bancos de inversión e inversionistas. Enrique es miembro del Comité de Inversiones de América Latina.<br /><br />
    Durante su carrera profesional en PGIM Real Estate, Enrique ha celebrado operaciones que de manera conjunta representan un total de US$6,000 millones, tanto en mercados públicos como privados, incluyendo inversiones en deuda, capital, fusiones y adquisiciones. <br /><br />
    Su experiencia laboral incluye casi 18 años en la industria de bienes raíces en México y  US$7,000 millones en transacciones en posiciones como vicepresidente de financiamiento inmobiliario y bursatilizaciones en Credit Suisse, así como director de originación de Créditos en GE Real Estate. De 2011 a 2012, Enrique fue miembro del Consejo de Administración de Paz Corp. S.A., una empresa constructora pública con sede en Chile, y actualmente es miembro del Comité de Inversiones de PGIM Real Estate México.<br /><br />
    Enrique es licenciado en Ingeniería Industrial del ITESM (campus Ciudad de México) y obtuvo una Maestría en Administración de Negocios (MBA) de la Universidad de Texas en Austin, en donde fue galardonado con el Premio Global Star.<br /><br />
    </>],
  },{
    nombre: "Rubén Duque",
    empresa: "",
    cargo: "",
    img: ponente8,
    url: sergio_con,
    semblanza: [<>
    Speaker internacional en más de 400 eventos, ha impactado a lideres y equipos en más de 10 países, incluyendo empresas del Fortune 500 como Google y Microsoft. Con más de 25 años como entrenador de rugby, ha trabajado en Nueva Zelanda y dirigido la Federación Española y Mexicana de Rugby, llevando a México a ser 5 veces campeón del Rugby Americas North.<br /><br />
    Ha colaborado con atletas olímpicos y líderes empresariales, y está certificado en cultura y liderazgo de equipos por Cambridge Business School y Disney Institute. Colaborador en El Economista y host del podcast “Equipos Invencibles”.<br /><br />
    Rubén aplica estrategias del deporte de alto rendimiento al mundo empresarial, inspirando a líderes a construir equipos de alto rendimiento.

    </>],
  },{
    nombre: "Marc Selvitelli",
    empresa: "",
    cargo: "",
    img: ponente9,
    url: sergio_con,
    semblanza: [<>
    Marc Selvitelli was selected by the NAIOP Board of Directors in 2022 as the association's president and chief executive officer. He is responsible for overseeing the association’s Corporate headquarters based in Herndon, Virginia. Through Selvitelli’s leadership and guidance, the headquarters staff is responsible for serving NAIOP’s 20,000+ members who are involved with the development, ownership and investment of industrial, office, retail and mixed-use properties throughout North America.<br /><br />
    Selvitelli first joined the association in 2006 where he served as NAIOP's vice president for membership and chapter relations until 2014. He returned to NAIOP in 2017 as the executive vice president and chief operating officer where he was responsible for running the association's day-to-day affairs.<br /><br />
    He possesses nearly 30 years of association management experience and has filled several roles with associations in the construction, real estate and engineering industries. Prior to returning to NAIOP in 2017, he worked for SmithBucklin where he served as the executive director of the Restoration Industry Association and the National Association of Local Housing Finance Agencies. His experience also includes roles in government affairs, strategic planning, chapter relations and membership development. <br /><br />
    He is a graduate of the Pennsylvania State University and received his Certified Association Executive (CAE) certification from the American Society of Association Executives. 
    </>],
  }
];
const Ponentes = (props) => {
  const ref_carousel = React.createRef();
  const [hover, setHover] = useState(false);
  const [selec_person, setSelectPerson] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      
      <CaretRightFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px' }} onClick={onClick} />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px', marginLeft:'-10px' }} onClick={onClick} />
    );
  }

  const settingsPonentes = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows:true,
    nextArrow:<SampleNextArrow /> ,
    prevArrow:<SamplePrevArrow  />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      }
    ]
  };
//sort acomoda alfabeticamente
  let ver_ponentes = expositor.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((seccion, index) => {
    return (
      <>
        <div className="contenedor_imagen_con">
          <img src={seccion.img} className="imagen_con" alt="" />
          <div className="overlay_con">
            <div className="text_con">
              <p className="nombre_con color_blanco bold_font">{seccion.nombre}</p>
              <br />
              <p className="empresa_con color_blanco">{seccion.empresa}</p>
              <p className="cargo_con color_blanco">{seccion.cargo}</p>
              <br />
              <p style={{display:'non',textAlign:'left'}}>
                {seccion.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setSelectPerson(index);
                }}
                className="btn_desc_con color_blanco"
              >
                <i>Leer semblanza</i>
              </span>
              :
              <></>}
              </p>
            </div>
          </div>
        </div>
        <br />
        </>
    );
  });
  let ver_texto_modal = expositor
    .filter((item, index) => {
      if (selec_person === index) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          <p className="nombre_modal">{seccion.nombre}</p>
          <p className="empresa_modal">{seccion.empresa}</p>
          <p className="cargo_modal">{seccion.cargo}</p>
          <br />
          <p className="semblanza_modal">{seccion.semblanza}</p>
          <br />
        </Col>
      );
    });

  return (
    <>
      <Row justify="center" align="middle" className="contenedor_ponentes">
        <Col span={24} offset={0}>
          <p className="textos_titulos_secciones color_azul">CONFERENCISTAS</p><br />
        </Col>
        <Col xs={10} md={16}>
       <Slider ref={ref_carousel} {...settingsPonentes} style={{margin:'0px', padding:'0px'}} className="slide_ponente">
        {ver_ponentes}
        </Slider>
        </Col>
      </Row>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </>
  );
};
export default Ponentes;
