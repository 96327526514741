import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/hospedaje.css';

import banner from '../../assets/images/tercera_reunion/HOSPEDAJE/hospedaje_titulo.png';

import oficial_sencilla from '../../assets/images/reunion_anual/HOSPEDAJE/hospedaje__info.jpg';
import agotado from '../../assets/images/segunda_2024/cupoagotado_ampip2024.jpg';

import costo_1 from '../../assets/images/anual_2024/home/habitacion_1.png';
import costo_2 from '../../assets/images/anual_2024/home/havitacion_2.png';

import icon_1 from '../../assets/images/anual_2024/home/icono_mail.png';
import icon_2 from '../../assets/images/anual_2024/home/icono_cel.png';
import icon_3 from '../../assets/images/anual_2024/home/icono_reservacion.png';
import img_titulo_hospedaje from '../../assets/images/reunion_anual/TITULOS/titulo_hospedaje.png';

const Hospedaje = (props) => {
    const [vista, setVista] = useState(false);
    const { idioma } = props;


    return (
        <>
            <Row className='back_hospedaje'>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} className=''>
                    <Row justify='center' align='middle' style={{ position: '' }}>

                        <Col span={24} offset={0} style={{ position: '', width: '100%' }}><br />

                        </Col>
                    </Row>
                </Col>

                {/*<Col xs={{span:22, offset:1}} md={{span:16, offset:4}}>
                <Row justify='center'>
                    <Col xs={22} md={20}>
                        <p className='color_azul text_safi'><span className='bold_font'>HOTEL MARRIOTT</span> - AGUASCALIENTES</p>
                        <p className='color_blanco text_safi_ubicacion'>BOULEVARD ZACATECAS NORTE S/N, COL. TROJES DE ALONSO, 20116 AGUASCALIENTES, AGS.
                        </p>
                       <br /><br />
                        <img src={agotado} style={{width:'100%'}} />
                    </Col>
                </Row>
            </Col>*/}
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 11, offset: 1 }}>
                    <Row justify={'center'}>
                        <Col xs={14} md={18}>
                            <a href="https://www.hilton.com/en/attend-my-event/pvrpmci-902-7a5784f5-cc09-4319-971e-f5be50ada410/" target='_blank'><img src={vista ? costo_2 : costo_1 } onMouseEnter={()=>setVista(true)} onMouseLeave={()=>setVista(false)} className="img_100" /></a>
                        </Col>
                    </Row>
                </Col>

                <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 0 }} style={{ display: 'non' }}><br /><br />
                    <p className="textos_titulos_secciones color_azul" style={{textAlign:'left'}}>HOSPEDAJE</p>
                    <p className='color_azul text_safi'><strong>Hotel Conrad</strong> <br />PUNTA DE MITA, NAYARIT, MÉXICO</p>
                    <p className='color_azul text_safi_ubicacion'>Carretera Punta de Mita - Sayulita, Km 2, 63734, Punta de Mita, Nayarit
                    </p>
                    <span className='no_ver_chico'><br /><br /><br /></span>
                    <p className="texto_hospedaje color_azul ">
                        <strong>Términos y condiciones</strong>
                        <ul style={{ listStyleType: '', padding: '0' }} className='color_azul'>
                            <li>Tarifas incluyen 16% de IVA, 5% de Impuesto sobre Hospedaje y 10% de cargo por servicio por noche.</li>
                            <li>Tarifas en base ocupación sencilla y en plan europeo.</li>
                            <li>Propinas no incluidas.</li>
                            <li>Cualquier cambio en la reservación podría incurrir en ajustes en la tarifa.</li>
                            <li>En caso de desear extender su reservación en fechas previas o posteriores (conforme a disponibilidad), favor de contactar al equipo de reservaciones del hotel.</li>
                        </ul>
                    </p>


                </Col>
            </Row>
            <Row className='back_azul_claro' style={{padding:'2% 0%'}}>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <Row justify={'center'} align={'middle'}>
                        <Col xs={20} md={24}>
                            <p className="texto_hospedaje_3 color_blanco">
                                <strong>MÉTODO DE RESERVACIONES</strong>
                            </p>
                            <p className="texto_hospedaje_4 color_blanco">
                                Las reservaciones son realizadas y garantizadas por cada participante
                            </p>


                        </Col>
                        <Col xs={24} style={{padding:'2%'}}>
                            <Row justify={'space-around'}>
                                <Col xs={{span:14}} md={{span:6}}>
                                    <img src={icon_1} className='icon_link_h'/>
                                    <p className='links_hospedaje'>
                                        <a className='color_blanco  hover_aqua' href='mailto:PVRPM.RES@ConradHotels.com'>PVRPM.RES@ConradHotels.com</a>
                                    </p>
                                </Col>
                                <Col xs={{span:12}} md={{span:6}}>
                                    <img src={icon_2} className='icon_link_h'/>
                                    <p className='links_hospedaje'>
                                        <a className='color_blanco  hover_aqua' href='tel:3292984300'>(329) 298 4300</a>
                                    </p>
                                </Col>
                                <Col xs={{span:12}} md={{span:6}}>
                                    <img src={icon_3} className='icon_link_h'/>
                                    <p className='links_hospedaje'>
                                        <a className='color_blanco  hover_aqua' href='https://www.hilton.com/en/attend-my-event/pvrpmci-902-7a5784f5-cc09-4319-971e-f5be50ada410/' target='_blank'>Reserva aquí</a>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={22} md={22}>
                            <p className='texto_hospedaje_2 color_blanco'>
                            <i>
                            AMPIP no realiza reservaciones de hotel.
                            <br />
                            La reservación de habitación en el hotel es independiente del registro y pago de la Convención.
                            </i>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    )
}
export default Hospedaje;